.Sidebar {
    font-size: 11px;
    margin: 0 10px;
    border-radius: 4px;
    padding: 5px 5px 5px 30px;
    color: #5e5e5e;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 400;
}

.Sidebar:hover {
    background-color: #f2f2f2;
}